"use client";

import { signIn, useSession } from "next-auth/react";
import Link, { LinkProps } from "next/link";
import { PropsWithChildren } from "react";

export type SessionLinkProps = Omit<LinkProps, "href"> & { href?: LinkProps["href"] };
export function SessionLink({ children, href, ...props }: PropsWithChildren<SessionLinkProps>) {
  const session = useSession();

  return (
    <Link href={href ?? SessionLink.href} {...props}>
      <button
        disabled={session.status === "loading"}
        onClick={(event) => {
          if (session.status !== "authenticated") event.preventDefault();
          if (session.status === "unauthenticated") signIn("google", { callbackUrl: `${href}` });
        }}
      >
        {children}
      </button>
    </Link>
  );
}
SessionLink.href = "/me";
